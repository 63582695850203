.coupon {
  margin: 10px;
  padding: 10px;
  background-color: white;

  .ant-form {
    margin-bottom: 10px;

    .ant-input {
      width: 200px;
    }
    .ant-select {
      width: 200px;
    }
  }
}
