.layout {
  min-width: 1270px;
  overflow: hidden;
  &.dark_theme {
    .header {
      background: #001529;
      color: #fff;
    }
    .sider {
      background: #001529;
      .logo {
        span {
          color: #fff;
        }
      }
      .siderFooter {
        border: 1px solid #001529;
        background: #001529;
      }
    }
    :global {
      .ant-layout-sider {
        &.single_column {
          //菜单单行
          .ant-menu-submenu {
            .ant-menu-sub {
              padding: 5px 0;
              .ant-menu-item {
                font-size: 13px;
                float: unset !important;
                // padding-left: 40px !important;
                // min-width: 45%;
                // width: auto;
                width: 100%;
                margin-top: 3px;
                padding-left: 16px;
                // display: flex;
                // justify-content: flex-start;
                // align-items: center;
                padding-right: 0;
                padding-left: 0;
                cursor: default;
                background: unset !important;
                margin: 0;
                span {
                  padding: 5px 6px;
                  border-radius: 2px;
                  line-height: 22px;
                  max-width: 100%;
                  .menu-icon {
                    position: absolute;
                    font-size: 21px;
                    top: 2px;
                  }
                }
                &.ant-menu-item-selected {
                  background: #1890ff !important;
                  a {
                    // background: #e6f7ff;
                    color: #fff;
                  }
                }
                &::after {
                  border-right: 3px solid #1890ff !important;
                }
              }
            }
          }
        }
        &.double_row {
          .ant-menu-submenu {
            .ant-menu {
              padding-left: 48px !important;
              .ant-menu-item {
                font-size: 13px;
                float: left;
                min-width: 49.5%;
                width: auto;
                display: block;
                margin-top: 3px;
                padding-right: 0;
                padding-left: 0 !important;
                cursor: pointer;
                background: transparent;
                margin: 0;
                .ant-menu-title-content {
                  a {
                    max-width: 100%;
                    position: relative;
                    // &:hover {
                    //   color: #1890ff !important;
                    // }
                    .menu-icon {
                      position: absolute;
                      font-size: 21px;
                      top: -5px;
                    }
                  }
                }

                &.ant-menu-item-selected {
                  .ant-menu-title-content {
                    a {
                      // background: #e6f7ff;
                      // color: #1890ff;
                    }
                  }
                }
                &::after {
                  border-right: unset;
                }
              }
            }
          }
        }
        &.ant-layout-sider-collapsed {
          flex: 0 0 80px !important;
          max-width: 80px !important;
          min-width: 80px !important;
          width: 80px !important;
        }
      }
    }
  }
  &.white_theme {
    :global {
      .ant-layout-sider {
        background: #fff;
        &.single_column {
          //菜单单行
          .ant-menu-submenu {
            .ant-menu-sub {
              padding: 5px 0;
              &.ant-menu-inline {
                background: #fff;
              }
              .ant-menu-item {
                font-size: 13px;
                float: unset !important;
                width: 100%;
                margin-top: 3px;
                padding-left: 16px;
                padding-right: 0;
                padding-left: 0;
                cursor: default;
                margin: 0;
                &:hover {
                  background: #e6f7ff !important;
                  span {
                    color: #1890ff !important;
                  }
                }
                &:nth-child(2n + 0) {
                  padding-left: 0;
                }
                span {
                  color: #666;
                  border-radius: 2px;
                  line-height: 22px;
                  max-width: 100%;
                  position: relative;
                }
                &.ant-menu-item-selected {
                  background: #e6f7ff !important;
                  span {
                    background: #e6f7ff;
                    color: #1890ff !important;
                  }
                }
                &::after {
                  border-right: 3px solid #1890ff !important;
                }
              }
            }
          }
        }
        &.double_row {
          .ant-menu-submenu {
            .ant-menu {
              padding-left: 48px !important;
              .ant-menu-item {
                font-size: 13px;
                float: left;
                min-width: 49.5%;
                width: auto;
                display: block;
                margin-top: 3px;
                padding-right: 0;
                padding-left: 0 !important;
                cursor: pointer;
                background: transparent;
                margin: 0;
                .ant-menu-title-content {
                  a {
                    color: #666;
                    max-width: 100%;
                    position: relative;
                    &:hover {
                      color: #1890ff !important;
                    }
                    .menu-icon {
                      position: absolute;
                      font-size: 21px;
                      top: -5px;
                    }
                  }
                }

                &.ant-menu-item-selected {
                  .ant-menu-title-content {
                    a {
                      background: #e6f7ff;
                      color: #1890ff;
                    }
                  }
                }
                &::after {
                  border-right: unset;
                }
              }
            }
          }
        }
        &::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }
        &.ant-layout-sider-collapsed {
          flex: 0 0 80px !important;
          max-width: 80px !important;
          min-width: 80px !important;
          width: 80px !important;
        }
        .ant-layout-sider-children {
          .ant-menu {
            &.ant-menu-dark {
              .ant-menu-submenu {
                .ant-menu-sub {
                  background: #000c18 !important;
                  .ant-menu-item {
                    &:hover {
                      color: #1890ff;
                    }
                    a {
                      color: unset;
                    }
                    &.ant-menu-item-selected {
                      a {
                        background: #1890ff;
                        color: #fff;
                      }
                    }
                  }
                }
              }
            }
            .ant-menu-submenu {
              .ant-menu-sub {
                padding: 5px 0;
                padding-right: 0px;
                &.ant-menu-inline {
                  background: #fff;
                }
                .ant-menu-item {
                  font-size: 13px;
                  padding-right: 0;
                  padding-left: 0;
                  cursor: pointer;
                  background: transparent;
                  margin: 0;
                }
              }
              .ant-menu-submenu-title {
                .ant-menu-sub {
                  .ant-menu-item {
                    margin: 0;
                  }
                }
                .ant-menu {
                  .ant-menu-item {
                    font-size: 13px;
                    .menuChild_link {
                      font-size: 13px;
                      position: relative;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    background: #ffffff;
    color: #3a3a3f;
    padding: 0 24px;
    justify-content: space-between;
    height: 40px;
    line-height: 40px;
    .logo {
      text-align: center;
      height: 32px;
      line-height: 32px;
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logo_company {
        margin-left: 20px;
        font-size: 13px;
        color: #797979;
        width: 150px;
        max-width: 150px;
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        white-space: nowrap; //溢出不换行
      }
      .ant_divider {
        margin: 0 30px;
      }
      img {
        height: 32px;
        // margin-right: 40px;
      }
      span {
        font-size: 17px;
        // padding-left: 10px;
        vertical-align: middle;
        animation: 3s linear 1s infinite running slidein;
      }
    }
    .brand {
      display: flex;
      align-items: center;
      .brand_left {
        display: flex;
        align-items: center;
      }
      .brand_right {
        display: flex;
        align-items: center;
      }
      .header_item {
        margin-right: 20px;
        :global {
          .anticon-ellipsis {
            svg {
              transform: rotate(90deg);
            }
          }
        }
        &.version {
          cursor: pointer;
          .version_info {
            display: flex;
            justify-content: center;
          }
        }
      }
      .H_dropdown {
        .ant-menu-item {
          padding: 5px 20px;
        }
      }
      .language {
        margin-left: 20px;
        cursor: pointer;
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  .layout_content {
    height: calc(100vh - 40px);
  }
  .content {
    .page_content {
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
    }
    .service_action {
      position: fixed;
      right: 0;
      bottom: 68px;
      z-index: 10000;
      cursor: pointer;
      .meiqia_box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        background: #1890ff;
        box-shadow: 0 2px 4px 0 #00000030;
        border-radius: 4px 0 0 4px;
      }
    }
    .help_prompt {
      position: fixed;
      right: 16px;
      bottom: 100px;
      z-index: 10000;
      .index_qr_box_new {
        width: 233px;
        position: absolute;
        left: -278px;
        bottom: -30px;
        background: linear-gradient(180deg, #fff, #fafcff);
        box-shadow: 0 4px 9px 0 #00000024;
        border-radius: 4px;
        &.hide {
          display: none;
        }
        .close {
          position: absolute;
          right: 2px;
          top: 2px;
          font-size: 14px;
          cursor: pointer;
          padding: 10px;
        }
        .content {
          padding: 22px 0 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          p {
            &:last-child {
              font-size: 14px;
              font-weight: 400;
              color: #333539;
              line-height: 28px;
            }
          }
          .title {
            font-size: 18px;
            font-weight: 600;
            color: #333435;
            line-height: 25px;
            margin-bottom: 0;
          }
          .text_gray {
            font-size: 13px;
            font-weight: 400;
            color: #8b8b8c;
            line-height: 28px;
          }
          img {
            width: 124px;
            height: 124px;
            margin: 14px auto 7px;
          }
        }
        .phone {
          height: 41px;
          line-height: 41px;
          border-top: 1px solid #eff0f1;
          font-size: 12px;
          font-weight: 700;
          color: #878788;
          text-align: center;
        }
      }
    }
  }
  .sider {
    height: calc(100vh - 40px);
    overflow-x: auto;
    overflow-y: auto;
    flex: 0 0 247px !important;
    max-width: 247px !important;
    min-width: 247px !important;
    width: 247px !important;
    position: relative;
    :global {
      .ant-menu {
        // height: calc(100% - 50px);
        height: calc(100% - 50px) !important;
        overflow-y: auto;
      }
    }
    .menuItem {
      color: rgba(0, 0, 0, 0.65);
      a {
        color: rgba(0, 0, 0, 0.65);
      }
      .menuChild_link {
        font-size: 13px;
      }
    }
    .menu_group_title {
      font-weight: 500;
      display: flex;
      align-items: center;
    }
    .siderFooter {
      position: absolute;
      bottom: 0;
      width: 100%;
      border-top: 1px solid #e5e5e5;
      height: 50px;
      line-height: 50px;
      background: #fff;
      padding: 0 20px;
      :global {
        .anticon {
          font-size: 15px;
        }
      }

      // text-align: center;
    }
  }

  :global {
    .ant-layout-sider {
      .ant-menu-submenu {
        .ant-menu-sub {
          padding: 5px 0;
          padding-right: 0px;
          .ant-menu-item {
            a {
              padding: 5px 6px;
              line-height: 22px;
              max-width: 100%;
              position: relative;
              .menu-icon {
                position: absolute;
                font-size: 21px;
                top: 2px;
              }
            }
          }
        }
      }
    }
    .ant-layout-content {
      box-sizing: border-box;
      min-height: auto;
    }
  }
}
:global {
  .ant-layout {
    // height: 100vh;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .ant-layout-sider-children {
      .ant-menu-light {
        &::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }
        .menuItem {
          // color: #000;
          padding-left: 12px !important;
        }
        .ant-menu-item-group {
          .ant-menu-item-group-title {
            color: #000;
            .title {
              display: flex;
              align-items: center;
            }

            .ant-menu-item-group-list {
              .ant-menu-item {
                color: #333;
              }
            }
          }
        }
      }
    }
  }
}
:global {
  .ant-table-thead {
    height: 49px !important;
  }

  .ant-menu-submenu-popup {
    left: 82px !important;
  }
  .ant-menu-submenu,
  .ant-menu-submenu-inline {
    padding: 2px !important;
  }

  .ant-menu-item > a {
    display: inline;
  }
}
.H_dropdown {
  .anticon-logout {
    margin: 0 5px;
  }
}
