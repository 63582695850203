.customerCommonInfo {
  display: flex;
  align-items: center;
  .avatar {
    width: 32px !important;
    height: 32px !important;
    margin-right: 5px;
    border-radius: 2px;
  }
  .customerinfo {
    width:calc(100% - 32px); 
    display: flex;
    flex-direction: column;
    .form {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      flex: 1;
    }
    .wx {
      color: #5ec75d;
    }
    .wb {
      color: #eda150;
    }
    .remark {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 200px;
      color: #bababa;
      font-size: 12px;
      cursor: pointer;
    }
  }
}