.setting-role {
  .container {
    margin: 0 15px 15px 15px;
    display: flex;
    .left {
      width: 200px;
      background: #fff;
      box-shadow: 0 2px 3px 0px #e2e2e1;
      height: 83vh;
      max-width: 83vh;
      overflow-y: auto;
      &__top {
        height: 50px;
        line-height: 50px;
        padding-left: 20px;
        // text-align: center;
        border-bottom: 1px dashed #eeeff0;
        cursor: pointer;
        background: #fafafa;
      }
      ul {
        li {
          cursor: pointer;
          padding: 5px 0;
          color: #8a8a8a;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 0 22px 0 24px;
          height: 40px;
          position: relative;
          &:hover {
            background: #effaff;
            .icon_action {
              display: block;
            }
          }
          .icon_action {
            display: none;
            font-size: 18px;
            position: absolute;
            width: 19px;
            right: 0;
          }
          p {
            width: 100%;
          }
          &.active {
            background: #effaff;
            color: #1890ff;
            position: relative;
            &::before {
              position: absolute;
              left: 0;
              top: 0;
              width: 3px;
              height: 100%;
              content: "";
              display: block;
              background-color: #1890ff;
              z-index: 1;
            }
          }
        }
      }
    }
    .right {
      background: #fff;
      flex: 1;
      margin-left: 8px;
    }
  }
}
