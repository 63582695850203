.user {
  .container {
    // display: flex;
    min-height: calc(100vh - 100px);
    margin: 15px;
    background: #fff;
    .left {
      width: 279px;
      min-width: 280px;
      overflow-y: auto;
      background: #fff;
      box-shadow: 0 0px 10px 0 #0000000d;
      border-radius: 2px;
      margin-right: 8px;
      overflow-x: auto;
      .title {
        font-size: 15px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        padding: 18px 16px 0;
      }
    }
    .right {
      flex: 1;
      background: #fff;
      overflow: hidden;
      .truename {
        display: flex;
        align-items: center;
        img {
          width: 32px;
          height: 32px;
          border-radius: 4px;
        }
        .trueright {
          margin-left: 10px;
          // overflow-y: scroll;
          p {
            font-size: 13px;
            color: #333;
            &:last-child {
              color: #bababa;
              font-size: 12px;
              margin-bottom: 0;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}
