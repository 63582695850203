.label-modal {
  .title {
    margin-bottom: 24px;
    font-size: 17px;
    width: 100%;
    text-align: center;
    margin-top: 2px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 1px;
  }
  .list {
    max-height: 450px;
    overflow: auto;
    ul {
      li {
        .parent {
          font-size: 13px;
          line-height: 18px;
          color: #999;
          white-space: nowrap;
          margin-bottom: 5px;
          word-break: break-all;
        }
        .tags {
          display: flex;
          flex-wrap: wrap;
          .tag_item {
            margin-bottom: 10px;
            padding: 5px 14px;
            border: 1px solid #e9e9e9;
            border-radius: 4px;
            font-size: 14px;
            line-height: 18px;
            box-sizing: border-box;
            height: 28px;
            margin-right: 10px;
            cursor: pointer;
            user-select: none;
            background: #f7f7f7;
            &.active {
              color: #1890ff;
              background: #e7f7ff;
              border: 1px solid #1890ff;
            }
            &.disabled {
              border-color: #e9e9e9;
              opacity: 0.6;
              color: rgba(0, 0, 0, 0.4);
              cursor: not-allowed !important;
              pointer-events: auto;
            }
          }
        }
      }
    }
  }
}
