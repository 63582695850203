
.MyFormItem__Image {
  margin-bottom: 5px;
  display: flex;
  align-items: flex-end;
}
.Image {
  outline: 1px solid #e8e8e8;
  display: inline-block;
  outline-offset: 2px;
  cursor: pointer;
  :global {
    .ant-upload-select {
      button {
        width: 100%;
        height: 100%;
        i {
          font-size: 30px;
          margin-bottom: 5px;
        }
      }
    }
  }
  .image{
    cursor: pointer;
  }
  .uploading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d9d9d9;
  }
}
.image{
  cursor: pointer;
}