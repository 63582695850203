.myColor {
  .swatch {
    width: 35px;
    height: 35px;
    cursor: pointer;
  }
  .popover {
    position: absolute;
    z-index: 2;
    .cover {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}
