.MyMaterialModal {
  .ant-modal-body {
    height: 500px;
    overflow: hidden;
  }
  .container {
    display: flex;
    .left {
      border-right: 1px solid #999;
      min-width: 160px;
      padding-right: 10px;
      .scrollableDiv {
        margin-top: 20px;
        height: 420px;
        max-height: 420px;
        overflow-y: auto;
        
      }
      .ant-list-item {
        cursor: pointer;
        padding-right: 10px;
        &.groupActive {
          background: linear-gradient(to right, #0682ef, #28abfa);
          span {
            color: #fff;
          }
        }
        .anticon-folder {
          color: #1990ff;
          font-size: 20px;
        }
        span {
          margin-left: 10px;
          max-width: 100px;
        }
      }
    }
    .right {
      flex: 1;
      margin-left: 20px;
    }
  }
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .total-box {
      display: flex;
      align-items: center;
      .border-left {
        width: 3px;
        height: 12px;
        background: #1990ff;
        border-radius: 2px;
        margin-right: 8px;
      }
      .total-txt {
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        line-height: 20px;
        margin-top: 1px;
      }
    }
    .ant-input-search {
      width: 203px;
    }
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    max-height: 400px;
    padding-right: 24px;
    overflow-y: auto;
    align-items: flex-start;
    .item {
      // width: 120px;
      background: #f9f9f9;
      border-radius: 4px;
      margin-right: 15px;
      margin-top: 16px;
      padding: 4px;
      transition: all 0.4s;
      border: 2px solid transparent;
      cursor: pointer;
      user-select: none;
      &:hover {
        border-color: #1990ff;
        background: #f6fcff;
      }
      &.active {
        border-color: #1990ff;
        background: #f6fcff;
        .anticon-check-circle {
          display: block !important;
        }
      }
      .check-box {
        position: absolute;
        right: 8px;
        top: 8px;
        .anticon-check-circle {
          width: 21px;
          height: 21px;
          color: #1990ff;
          display: none;
        }
      }
      .image{
        width: 120px;
        .top_image {
          // height: 295px;
          height: 100px;
          width: 100%;
          margin-bottom: 12px;
          background: #fff;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }

        }
        .bottom {
          display: flex;
          min-height: 22px;
          .hb_tit {
            max-width: 150px;
            overflow: hidden;
            white-space: nowrap;
            word-break: keep-all;
            text-overflow: ellipsis;
          }
        }
      }
      .link{
        width: 200px;
        padding: 8px;
        position: relative;
        .radar-detail{
          justify-content: flex-start;
          .desc{
            flex: 1;
          }
          img{
            width: 48px;
            height: 48px;
            object-fit: cover;
            margin-left: 4px;
          }
        }
      }
      .miniprogram{
        position: relative;
        width: 200px;
        padding: 8px;
        .miniprogram_img{
          width: 100%;
          height: 100px;
        }
      }
    }
  }
  .ant-pagination{
    position: absolute;
    bottom: 58px;
    right: 20px;
  }
}
