.home-page {
  margin: 10px;
  .add-border {
    position: relative;
    &::after {
      position: absolute;
      content: ' ';
      right: 0;
      top: 25%;
      width: 1px;
      height: 50%;
      background-color: #eee;
    }
  }
  .flex-view {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #fff;

    .info-view {
      text-align: center;
      .title {
        font-size: 32px;
        line-height: 32px;
        font-weight: bold;
        margin-bottom: 0;
      }
      .desc {
        font-size: 14px;
        color: #666;
        margin-top: 20px;
      }
    }
  }
  .user-header-img {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    display: block;
  }
  .company-name {
    font-size: 22px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
}
