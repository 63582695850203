// @font-face {
//   font-family: "iconfont"; /* Project id 3549833 */
//   src: url('//at.alicdn.com/t/c/font_3549833_bltzqg792xw.woff2?t=1667956909410') format('woff2'),
//        url('//at.alicdn.com/t/c/font_3549833_bltzqg792xw.woff?t=1667956909410') format('woff'),
//        url('//at.alicdn.com/t/c/font_3549833_bltzqg792xw.ttf?t=1667956909410') format('truetype');
// }

// .iconfont {
//   font-family: "iconfont" !important;
//   font-size: 16px;
//   font-style: normal;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// .icon-SCRM-icofont-01:before {
//   content: "\ecd3";
// }

// .icon-SCRM-icofont-02:before {
//   content: "\ecd4";
// }

@font-face {
  font-family: 'iconfont'; /* Project id 4118866 */
  src: url('//at.alicdn.com/t/c/font_4118866_cugm30fli6h.woff2?t=1686586098305') format('woff2'),
    url('//at.alicdn.com/t/c/font_4118866_cugm30fli6h.woff?t=1686586098305') format('woff'),
    url('//at.alicdn.com/t/c/font_4118866_cugm30fli6h.ttf?t=1686586098305') format('truetype');
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-yingxiao:before {
  content: '\e647';
}

.icon-shezhi:before {
  content: '\e61c';
}

.icon-xiansuoguanli:before {
  content: '\e6b2';
}

.icon-yonghushouquan:before {
  content: '\e6ac';
}

.icon-shouye1:before {
  content: '\e600';
}

.icon-douyinxiaochengxu:before {
  content: '\e610';
}
