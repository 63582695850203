.remember-auto {
  .ant-checkbox::after {
    content: '' !important;
  }
  .ant-checkbox {
    .ant-checkbox-inner {
      background-color: #d8d9da;
      border-color: #d8d9da;
    }
    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: #d4515d;
        border-color: #d4515d;
      }
    }
  }
}
.ant-btn-primary {
  border: 0;
}
