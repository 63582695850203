.users_modal {
  .ant-modal-body {
    max-height: 800px;
    h2 {
      font-size: 17px;
      width: 100%;
      text-align: center;
      margin-top: 2px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 1px;
    }
  }
  .info {
    margin-bottom: 0;
    font-size: 13px;
    margin-top: 16px;
    .span {
      color: #1890ff;
      cursor: pointer;
    }
  }
  .user_content {
    display: flex;
    // align-items: center;
    .user_list {
      padding-right: 20px;
      height: 287px;
      overflow-y: auto;
      margin-bottom: 0;
      li {
        width: 263px;
        display: -webkit-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 12px;
        cursor: pointer;
        user-select: none;
        .userInfo {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
          .avatar {
            margin-right: 10px;
            border-radius: 2px;
            width: 35px;
            height: 35px;
          }
          .user_name {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
            padding: 0;
          }
        }
      }
    }
    &__left {
      width: 291px;
      display: flex;
      flex-direction: column;
      border-right: 1px solid #e8e8e8;
      &__top {
        margin-bottom: 0;
        font-size: 13px;
        margin-top: 16px;
        padding-right: 32px;
        .ant-checkbox-wrapper {
          font-size: 13px;
        }
      }
      .all-member {
        padding-top: 11px;
        overflow: auto;
        height: 400px;
      }
    }
    &__right {
      display: flex;
      flex-direction: column;
      padding-left: 31px;
      p {
        margin-bottom: 0;
        font-size: 13px;
        margin-top: 16px;
      }
      .selected_list {
        height: 400px;
        li {
          width: 233px;
          cursor: default;
        }
      }
    }
  }
}
