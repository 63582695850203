

  .iconfont{
    min-width: 14px;
    // margin-right: 8px;
    font-size: 14px;
    display: inline-block;
    font-style: normal;
    vertical-align: baseline;
    text-align: center;
    text-transform: none;
    line-height: 1;
    text-rendering: optimizeLegibility;
  }
  .ant-menu-item .iconfont, .ant-menu-submenu-title .iconfont {
    min-width: 14px;
    // margin-right: 10px;
  }
  .ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .iconfont+span,
  .ant-menu-inline-collapsed>.ant-menu-item .iconfont+span,
  .ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title .iconfont+span {
    max-width: 0;
    display: inline-block;
    opacity: 0;
  }
  .ant-tabs-nav .ant-tabs-tab .iconfont {
    margin-right: 8px;
  }