@import "./var";
@import "~antd/dist/antd.css";
@import "./iconfont";
body {
  max-height: 100vh;
  // overflow: hidden;
  overflow-y: hidden;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei,
    Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  p {
    margin-bottom: 0;
  }
}
#root {
  overflow-x: auto;
}
.login_form {
  .ant-row {
    display: block;
  }
}
.page-content {
  position: relative;
  overflow-y: auto;
  .commonContent {
    margin: 15px;
  }
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}
.flex_row {
  display: flex;
  align-items: center;
}
.omit {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box; //作为弹性伸缩盒子模型显示。
  -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
  -webkit-line-clamp: 2; //显示的行
}

.ant-input-number-input {
  font-size: 14px;
}
.ant-card {
  &.page-ant-card {
    .ant-card-body {
      padding: 0 0 30px 0;
    }
    .ant-table .ant-table-tbody > tr > td {
      font-size: 13px;
    }
  }
}

.grouplist-tag-boxs {
  max-height: 54px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  cursor: pointer;
}
.action {
  margin: 15px;
}
.text-counter {
  text-align: right;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 18px;
  padding: 5px;
}
.ant-btn {
  &.ant-btn-link {
    border: unset;
    padding: 5px;
  }
}
.ant-layout-sider-trigger {
  background: #343435;
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
  margin: 2px 0;
}
.ant-table {
  .ant-table-thead > tr > th {
    color: rgba(65, 66, 74, 0.85);
    font-weight: 400;
    border-bottom: unset;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f7f7f7;
    padding: 10px 10px;
  }
}
.ant-pagination {
  margin-right: 10px;
}
.ant-spin {
  width: 100%;
}
.ant-modal {
  .modal_header {
    text-align: center;
    font-size: 17px;
    width: 100%;
    text-align: center;
    margin-top: 2px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 1px;
    margin-bottom: 16px;
  }
  .ant-alert {
    margin-bottom: 16px;
    .ant-alert-message {
      font-size: 13px;
      color: rgba(0, 0, 0, 0.65);
      line-height: 18px;
      // margin-bottom: 24px;
    }
  }
  .ant-legacy-form {
    .ant-legacy-form-item-control-wrapper {
      width: 200px;
    }
  }
}

.ant-dropdown {
  .ant-table-filter-dropdown {
    .button {
      cursor: pointer;
    }
    .btn_group {
      .button {
        cursor: pointer;
      }
    }
  }
}
.ant-layout-footer {
  padding: 8px 0;
  // position: fixed;
  bottom: 0;
  width: calc(100% - 200px);
  margin: 0 auto;
}

.gend-con {
  width: 150px;
  .text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 2px;
}
.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-inline .ant-menu-submenu {
  padding: 6px 0;
}

.ant-table.ant-table-scroll-position-left .ant-table-fixed-left {
  z-index: 0;
  &.ant-table-fixed-right {
    z-index: 0;
  }
}

// 群发列表 modal层
.mask-box {
  z-index: 9;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  background: rgba(0, 0, 0, 0.7);
  .preview-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .close-box {
      position: absolute;
      top: -3%;
      cursor: pointer;
      right: -31px;
      font-size: 30px;
      color: #fff;
      width: 34px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.preview-multi-reply-phone-box {
  position: relative;
  width: 274px;
  height: 535px;
  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
  .content {
    width: 255px;
    height: 395px;
    position: relative;
    top: 80px;
    left: 19px;
    z-index: 1;
    padding-right: 18px;
    .reply-list {
      padding-left: 12px;
      overflow: auto;
      height: 97%;
      overflow-x: hidden;
      li {
        display: flex;
        align-items: flex-start;
        margin-top: 10px;
        width: 200px;
        img {
          width: 26px;
          height: 26px;
        }
        .msg {
          margin-left: 10px;
          border-radius: 4px;
          overflow: hidden;
          background: #fff;
          font-size: 12px;
          &.text {
            width: 168px;
            border-radius: 4px;
            background: #fff;
            padding: 8px 13px;
            word-break: break-all;
            white-space: pre-wrap;
            line-height: 20px;
          }
          &.image {
            width: 140px;
            border-radius: 4px;
            overflow: hidden;
            background: #fff;
            img {
              width: 100%;
              height: 100%;
            }
          }
          &.link {
            width: 168px;
            border-radius: 4px;
            background: #fff;
            padding: 8px 13px;
            .title {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              word-break: break-all;
              font-size: 12px;
              font-weight: 600;
              color: rgba(0, 0, 0, 0.84);
              width: unset !important;
              text-align: left;
              line-height: 20px;
            }
            .link-inner {
              display: flex;
              margin-top: 4px;
              .desc {
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                height: 35px;
                font-size: 12px;
                color: rgba(0, 0, 0, 0.64);
              }
              img {
                margin-left: 6px;
                object-fit: cover;
                width: 40px;
                height: 40px;
              }
            }
          }
          &.miniprogram {
            padding: 8px 13px;
            .title {
              line-height: 15px;
            }
            .miniprogram_img {
              width: 126px;
              height: 126px;
            }
            p {
              padding-top: 8px;
              font-size: 10px;
              color: rgba(0, 0, 0, 0.45);
              border-top: 1px solid #f2f2f2;
              line-height: 20px;
              .anticon-share-alt {
                color: #696ee2;
              }
              span {
                padding-left: 5px;
              }
            }
          }
        }
      }
    }
  }
}

.ant_tag {
  box-sizing: border-box;
  margin: 2px;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  display: inline-block;
  height: auto;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  cursor: default;
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.voice_style {
  margin: 0.1rem 0;
  color: #333;
  height: 40;
  cursor: pointer;
  line-height: 35px;
  padding: 3px 10px;
  background-color: #fff3ea;
  border: 1px solid #ffe0c9;
  border-radius: 4px;
  position: relative;
  float: left;
}

.voice_style .s1 {
  width: 28px;
  height: 28px;
  position: absolute;
  // right: 0.2rem;
  left: 10px;
  top: 6px;
  // ../images/sound.png
  background: url(../image/sound.png) no-repeat;
  background-size: 18px 22px;
}

.voice_style .s2 {
  width: 28px;
  height: 28px;
  position: absolute;
  // right: 0.2rem;
  left: 10px;
  top: 8px;
  background: url(../image/call.png) no-repeat;
  background-size: 18px 22px;
}

.size {
  font-size: 10px;
  margin-left: 5px;
}

.voice_style .s11 {
  width: 28px;
  height: 28px;
  position: absolute;
  // right: 0.2rem;
  left: 10px;
  top: 6px;
  // ../images/sound.png
  background: url(../image/sound.png) no-repeat;
  background-size: 18px 22px;
}

.voice_style .s21 {
  width: 28px;
  height: 28px;
  position: absolute;
  // right: 0.2rem;
  left: 10px;
  top: 6px;
  background: url(../image/call.png) no-repeat;
  background-size: 18px 22px;
}
.ant_form {
  .ant-form-item-label {
    min-width: 100px;
    max-width: 120px;
  }
  .ant-input-textarea-show-count {
    height: 140px;
    &::after {
      position: absolute;
      right: 7px;
      bottom: 24px;
    }
  }
}

//客户头像和名称公共样式
.ant-table {
  .customerCommonInfo {
    display: flex;
    align-items: center;
    .avatar {
      width: 32px;
      height: 32px;
      margin-right: 5px;
      border-radius: 2px;
    }
    .customerinfo {
      display: flex;
      flex-direction: column;
      .form {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        flex: 1;
      }
      .wx {
        color: #5ec75d;
      }
      .wb {
        color: #eda150;
      }
      .remark {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 200px;
        color: #bababa;
        font-size: 12px;
        cursor: pointer;
      }
    }
  }
}

.Commonfilters {
  margin-bottom: 15px;
  padding: 20px 24px;
  border-radius: 2px;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .filter-item {
    display: flex;
    align-items: center;
    margin-right: 16px;
    .label {
      color: rgba(0, 0, 0, 0.65);
    }
    .dropdown-wrap {
      display: flex;
      .label-wrap {
        border: 1px solid #dcdfe6;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 4px 10px;
        display: flex;
        align-items: center;
        width: 215px;
        height: 36px;
        background-color: #fff;
        cursor: pointer;
        .default-label {
          flex: 1;
          overflow: hidden;
          word-break: keep-all;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: rgba(0, 0, 0, 0.45);
          padding-left: 6px;
          .customer-select-show-view {
            display: flex;
            .staff-item {
              display: flex;
              img {
                width: 21px;
                height: 21px;
                margin-right: 4px !important;
              }
              &::after {
                content: "，";
              }
              &:last-child {
                &::after {
                  content: "";
                }
              }
            }
          }
        }
        .anticon-down {
          color: rgba(0, 0, 0, 0.45);
        }
      }
    }
  }
}

.disabled {
  pointer-events: none;
  cursor: default;
}
.anticon-question-circle {
  color: #666;
  margin-left: 5px;
}
.corp {
  color: #fa9a34;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wx {
  color: #5ec75d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.uniline {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  &.primary {
    color: #1890ff;
    cursor: pointer;
  }
}
