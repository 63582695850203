.header {
  background: #fafafa;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  font-size: 15px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  width: 100%;
  // position: fixed;
  // top: 40px;
  // z-index: 9;
  .info {
    cursor: pointer;
    &:hover{
      color: #1890ff;
    }
  }
  .ant-divider {
    background: #1c90ff;
    height: 0.9em;
    width: 2px;
  }
}
