.miniprogram {
  background-color: white;
  margin: 10px;
  height: calc(100% - 20px);
  overflow-y: scroll;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f2f2f2;
    padding: 0 20px;
    height: 60px;

    .title {
      font-weight: 500;
      font-size: 14px;
    }
  }

  .step-wrap {
    padding: 20px 20px 0 20px;
  }

  .flex-wrap {
    display: flex;
    flex-wrap: wrap;
    padding: 0 24px 24px 24px;
    .flex-item {
      min-width: 330px;
      min-height: 102px;
      position: relative;
      display: flex;
      align-items: center;
      margin-top: 24px;
      margin-right: 20px;
      padding: 14px 24px;
      border-radius: 8px;
      background: #f9f9f9;
      border: 1px solid #f9f9f9;
      cursor: pointer;
      .app-icon {
        width: 66px;
        height: 66px;
        border-radius: 66px;
        margin-right: 18px;
      }
      .item-info {
        .item-info-title {
          font-size: 16px;
        }
        .item-info-title {
          color: #999;
          margin-top: 4px;
        }
      }
      .upload-icon {
        position: absolute;
        right: 15px;
        top: 15px;
      }
    }
    .flex-item:hover {
      background: #f5f8ff;
      border: 1px solid #4073fa;
      .item-info-title {
        color: #4073fa;
      }
    }
    .flex-item-new {
      min-width: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 24px;
      margin-right: 20px;
      padding: 0 24px;
      border-radius: 8px;
      background: #f9f9f9;

      border: 1px solid #ccc;
      cursor: pointer;
      span {
        font-size: 40px;
        color: #ccc;
      }
    }
  }
}

.settle {
  background-color: white;
  height: calc(100% - 20px);
  margin: 10px;

  .form-wrap {
    height: 100%;
    overflow-y: scroll;
    padding: 20px 20px 0 20px;

    .ant-picker {
      width: 100%;
    }
  }
}

.settle-result {
  display: flex;
  align-items: center;
  justify-content: center;
}
