.user-privace {
  margin: 10px;
  padding: 15px;
  background-color: #fff;
  .react-quill-el {
    .ql-container {
      height: calc(600px - 44px);
    }
  }
}
