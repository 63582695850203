body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
dl,
dt,
dd,
ul,
ol,
li,
form,
button,
input,
textarea,
a,
i,
em,
b,
fieldset,
form,
label,
legend {
    margin: 0;
    padding: 0;
}

* {
    /* touch-action: none; */
}

body {
    font-family: "Microsoft YaHei", Helvetica, Arial, sans-serif;
    font-size: 14px;
    background: rgb(235, 239, 242)!important;
    /*-webkit-user-select: none;*/
    -webkit-tap-highlight-color: transparent;
    color: #333;
}


/*user-select: none;用户不能选择元素中的任何内容。
 tap-highlight-color:transparent;去掉ios跟安卓点击之后出现默认背景灰色。*/

ol,
ul {
    list-style: none;
}

ul {
    margin-bottom: 0!important
}

input,
button,
textarea,
select {
    font-family: "Microsoft YaHei", Helvetica, Arial, sans-serif;
    font-size: 16px;
    outline: none;
    border: none;
    -webkit-appearance: none;
}


/* a,
s {
    text-decoration: none;
    color: #666;
} */

img {
    border: 0;
    vertical-align: middle;
}

/* h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    font-weight: normal;
} */

/* i,
em {
    font-style: normal;
}

b {
    font-weight: normal;
}

textarea {
    resize: none;
} */


/*取消自动调节大小*/

/* * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
} */


/*box-sizing: border-box;对象的实际宽度就等于设置的width值，即使定义有border和padding也不会改变对象的实际宽度。*/


/*清浮动*/

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}


/*取消默认滚动条
::-webkit-scrollbar{width: 0;}*/


/*浮动*/

/* .fl {
    float: left;
}

.fr {
    float: right;
} */


/*字号*/

/* .f12 {
    font-size: 12px!important;
}

.f13 {
    font-size: 13px!important;
}

.f14 {
    font-size: 14px!important;
}

.f15 {
    font-size: 15px!important;
}

.f16 {
    font-size: 16px!important;
} */


/*背景颜色*/

/* .bg-white {
    background-color: #fff!important;
}

.bg-blue {
    background: #1890ff;
} */


/*字体颜色*/

/* .color-white {
    color: #fff!important;
}

.color-black {
    color: #000!important;
}

.color-grey {
    color: #333!important;
}

.color-lightgrey {
    color: #666!important
}

.color-lightergrey {
    color: #999!important
}

.color-blue {
    color: #1890ff!important
}

.texthide {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.banxin-width {
    padding: 0 4%
}

.text-center {
    text-align: center
}

.font-bold {
    font-weight: bold
}

.m-l-5 {
    margin-left: 5px!important;
}

.m-r-5 {
    margin-right: 5px!important;
}

.m-l-10 {
    margin-left: 10px!important;
}

.m-r-10 {
    margin-right: 10px!important;
}

.m-l-15 {
    margin-left: 15px!important;
}

.m-r-15 {
    margin-right: 15px!important;
}

.p-l-5 {
    padding-left: 5px!important;
}

.p-r-5 {
    padding-right: 5px!important;
}

.p-l-10 {
    padding-left: 10px!important;
}

.p-r-10 {
    padding-right: 10px!important;
}

.p-l-15 {
    padding-left: 15px!important;
}

.p-r-15 {
    padding-right: 15px!important;
}

.m-t-20 {
    margin-top: 20px!important;
}

.b {
    border: 1px solid #1890ff;
}

.b-t {
    border-top: 1px solid #e8e8e8
}

.b-b {
    border-bottom: 1px solid #e8e8e8
}

.display-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex
}

.flex {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1
} */


/* 修改下拉菜单样式 */

/* .ant-dropdown-menu {
    padding: 0!important
} */

.ant-dropdown-menu-item {
    /* padding: 5px 40px 5px 12px!important */
}


/* 修改表格样式 */

/* .change_style .ant-table {
    color: #333
}

.change_style .ant-table-thead>tr>th {
    font-size: 12px;
    color: rgba(153, 153, 153, 1)
} */