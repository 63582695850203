.customer-list {
  padding: 15px;
  .filter {
    margin-bottom: 15px;
    padding: 20px 24px;
    border-radius: 2px;
    // box-shadow: 0 1px 3px 0 rgb(0 0 0 #0000000d);
    background-color: #fff;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .filter-item {
      display: flex;
      align-items: center;
      margin-right: 10px;
      .dropdown-wrap {
        display: flex;
        .label-wrap {
          border: 1px solid #dcdfe6;
          box-sizing: border-box;
          border-radius: 4px;
          padding: 4px 10px;
          display: flex;
          align-items: center;
          width: 215px;
          height: 36px;
          background-color: #fff;
          cursor: pointer;
          .default-label {
            flex: 1;
            overflow: hidden;
            word-break: keep-all;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: rgba(0, 0, 0, 0.45);
            padding-left: 6px;
            .customer-select-show-view {
              display: flex;
              .staff-item {
                display: flex;
                img {
                  width: 21px;
                  height: 21px;
                  margin-right: 4px !important;
                }
                &::after{
                  content: '，';
                }
                &:last-child{
                  &::after{
                    content: '';
                  }
                }
              }
            }
          }
          .anticon-down {
            color: rgba(0, 0, 0, 0.45);
          }
        }
      }
    }
  }
  .ant-card {
    .ant-table {
      .external {
        .avatar{
          width: 32px;
          height: 32px;
          margin-right: 5px; 
          // borderRadius: '2px'
          border-radius: 2px;
        }
        .customerinfo {
          display: flex;
          flex-direction: column;
          .form{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            // width: 100px;
            cursor: pointer;
            flex: 1;
          }
          .wx {
            color: #5ec75d;
          }
          .wb {
            color: #eda150;
          }
          .remark {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 200px;
          }
        }
      }
      .tag-content {
        .min-height {
          // max-height: 54px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          cursor: pointer;
          .ant_tag{
            border: 2px solid #EFF2F5;
            color: #929BAF;
            border-radius: 20px;
            padding: 3px 5px;
            font-size: 12px;
          }
        }
        .btn {
          cursor: pointer;
        }
      }
    }
  }
}
